import React, {Fragment} from 'react';

import withMainLayout from 'layouts/main/view';
import CurrentMembers from './components/current-members';

class LeaderboardPage extends React.Component {
  render() {
    return (
      <Fragment>
        <article>
          <CurrentMembers />
        </article>
        <div className="spacer" />
        <article className="tcr-info">
          Sourcing data from TCRP Registry at <span className="code">0x0ba217252e67ab3832fbfc6af9b0ab4132d6eb84</span> (Rinkeby network).
        </article>
      </Fragment>
    );
  }
}

export default withMainLayout(LeaderboardPage);
