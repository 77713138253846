import React from 'react';
import {Link} from 'react-router-dom';

import withAnalytics from 'utils/analytics';

class MainLayout extends React.Component {
  render() {
    return (
      <div className="container">
        <header>
          <h1>
            🎉 TCR Party 🎉
            <span className="alpine">By <a href="https://www.alpineintel.com">Alpine</a></span>
          </h1>
          <ul>
            <li><Link to="/">About</Link></li>
            <li><a href="https://github.com/alpineintel/tcrpartybot">Code</a></li>
            <li><Link to="/leaderboard">Leaderboard</Link></li>
          </ul>
        </header>
        {this.props.children}
      </div>
    );
  }
}

const WrappedMainLayout = (Component) => {
  const AnalyticsComponent = withAnalytics(Component);

  return () => (
    <MainLayout>
      <AnalyticsComponent />
    </MainLayout>
  );
};

export default WrappedMainLayout;
