import React, {Fragment} from 'react';
import moment from 'moment';

const generateDM = (text) => {
  return `https://twitter.com/messages/compose?recipient_id=1029028522843627520&text=${text}`;
}

export default class WhitelistListing extends React.Component {
  render() {
    const {listing} = this.props;

    const handle = listing.twitter_handle;
    const dateString = `Added to the list ${moment(listing.application_ended_at).fromNow()}`;
    const actions = (
      <Fragment>
        <a rel="noopener noreferrer" target="_blank" href={generateDM(`challenge @${handle}`)}>Challenge</a>
      </Fragment>
    );

    return <li>
      <img src={`https://avatars.io/twitter/${handle}`} alt={`@${handle}'s avatar`} />
      <div className="meta">
        <a rel="noopener noreferrer" target="_blank" className="handle" href={`https://twitter.com/${handle}`}>@{handle}</a>
        <span className="date">{dateString}</span>
        {actions && <div className="actions">{actions}</div>}
      </div>
    </li>;
  }
}
