import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import ReactGA from 'react-ga';

import LeaderboardPage from './pages/leaderboard/view';
import AboutPage from './pages/about/view';

ReactGA.initialize('UA-131202545-2');

class App extends React.Component {
  render() {
    return (
      <Router>
        <Fragment>
          <Route exact path="/leaderboard" component={LeaderboardPage} />
          <Route exact path="/" component={AboutPage} />
        </Fragment>
      </Router>
    );
  }
}

export default App;
