import React, {Fragment} from 'react';
import moment from 'moment';

const generateDM = (text) => {
  return `https://twitter.com/messages/compose?recipient_id=1029028522843627520&text=${text}`;
}

export default class ChallengeListing extends React.Component {
  render() {
    const {listing} = this.props;

    const handle = listing.twitter_handle;
    const now = + new Date();
    let dateString;
    let statusString;
    let actions;

    if (Date.parse(listing.challenge.commit_ends_at) < now) {
      dateString = `Challenge finalized ${moment(listing.challenge.reveal_ends_at).fromNow()}`;
    } else {
      dateString = `Challenge vote ends ${moment(listing.challenge.commit_ends_at).fromNow()}`;
      actions = (
        <Fragment>
          Vote to&nbsp;
          <a rel="noopener noreferrer" target="_blank" href={generateDM(`vote @${handle} keep`)}>keep</a> or&nbsp;
          <a rel="noopener noreferrer" target="_blank" href={generateDM(`vote @${handle} kick`)}>kick</a>
        </Fragment>
      );
    }

    if (listing.whitelisted_at !== null) {
      statusString = "List member";
    } else {
      statusString = "Nominee";
    }

    return <li>
      <img src={`https://avatars.io/twitter/${handle}`} alt={`@${handle}'s avatar`} />
      <div className="meta">
        <a rel="noopener noreferrer" target="_blank" className="handle" href={`https://twitter.com/${handle}`}>@{handle}</a>
        {statusString && <span className="status">{statusString}</span>}
        <span className="date">{dateString}</span>
        {actions && <div className="actions">{actions}</div>}
      </div>
    </li>;
  }
}
