import React, {Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
import ChallengeListing from './challenge-listing';
import NominateListing from './nominate-listing';
import WhitelistListing from './whitelist-listing';

export default class CurrentMembers extends React.Component {
  constructor() {
    super();
    this.state = {
      challenged: [],
      whitelisted: [],
      nominated: [],
    };
  }

  componentDidMount() {
    let url = 'https://tcrparty.teif.xyz/api/list'

    fetch(url)
    .then(results => {
     return results.json()
     }).then(data => {
      this.setState({
        whitelisted: sortByAppEnd(data.whitelisted),
        challenged: sortChallenges(data.challenges),
        nominated: sortByAppEnd(data.applications),
      })
    })

    const sortByAppEnd = (listings) => {
      const sorted = listings.sort((a, b) => {
        if (a.application_ended_at < b.application_ended_at) {
          return -1;
        } else if (a.application_ended_at > b.application_ended_at) {
          return 1;
        }
        return 0;
      }); 
      return sorted;
    }

    const sortChallenges = (listings) => {
      const now = + new Date();
  
      const isReveal = x => Date.parse(x.challenge.commit_ends_at) >= now;
  
      const sorted = listings.sort((a, b) => {
        if (isReveal(a) && !isReveal(b)) {
          return -1;
        } else if (!isReveal(a) && isReveal(b)) {
          return 1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at < b.challenge.reveal_ends_at) {
          return -1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at > b.challenge.reveal_ends_at) {
          return 1;
        } else if (isReveal(a) && isReveal(b) && a.challenge.reveal_ends_at === b.challenge.reveal_ends_at) {
          return 0;
        } else if (a.challenge.commit_ends_at < b.challenge.commit_ends_at) {
          return -1;
        } else if (a.challenge.commit_ends_at > b.challenge.commit_ends_at) {
          return 1;
        }
      return 0;
      });
      return sorted;
    }
  

  }

  wrap = (text) => {
    return text
      .split(' ')
      .map((word, i) => {
        if (i !== 0 && i % 7 === 0) return `${word}<br>`;
        else return word;
      })
      .join(' ');
  }

  render() {
    const {whitelisted, nominated, challenged} = this.state;

    const total = whitelisted.length + nominated.length + challenged.length;

    return (
      <Fragment>
        <ReactTooltip multiline />
        <p className="list-stats">
          The list has {total} out of 99 spots filled.<span className="help" data-tip="We can't all be famous 🤷‍♀️">?</span>
        </p>
        <div className="lists">
          <div className="list">
            <h2>
              Nominated to join
              <span className="help" data-tip={this.wrap("These Twitter users have been nominated to be on the TCR but must wait 12 hours before they're added. During this nomination period anybody may submit a challenge in order to block them from being added.")}>?</span>
            </h2>
            {nominated.length === 0 && (
              <p>None</p>
            )}
            <ul className="members">
              {nominated.map((listing) => <NominateListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
          <div className="list">
            <h2>
              In challenge
              <span className="help" data-tip={this.wrap("These users have either been nominated to the TCR or are already members of the list, but somebody has initiated a challenge to remove them. During the voting period you may choose to keep or kick them.")}>?</span>
            </h2>
            {challenged.length === 0 && (
              <p>None</p>
            )}
            <ul className="members">
              {challenged.map((listing) => <ChallengeListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
          <div className="list">
            <h2>
              On the list
              <span className="help" data-tip={this.wrap("These users are on the TCR and their Tweets are being retweeted by TCRPartyBot.")}>?</span>
            </h2>
            {whitelisted.length === 0 && (
              <p>None</p>
            )}
            <ul className="members">
              {whitelisted.map((listing) => <WhitelistListing listing={listing} key={listing.listingHash} />)}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}
